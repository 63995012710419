/**
 * 商户接口
 */
export default SERVER => ({
  /**
   * 商户活动配置
   * method GET
   */
  MERCHANT_ACTIVITY_CONFIG: `${SERVER}/merchant/merchant/activity/getActivitySetting`,

  /**
   * 商户活动配置编辑
   * method POST
   */
  MERCHANT_ACTIVITY_EDIT: `${SERVER}/merchant/merchant/activity/modifyActivitySetting`,

  /**
   * 门店房间类型
   * method GET
   */
  MERCHANT_ROOM_TYPE_LIST: `${SERVER}/merchant/store/itemType/getItemTypeList`,

  /**
   * 门店类型编辑
   * method POST
   */
  MERCHANT_ROOM_TYPE_EDIT: `${SERVER}/merchant/store/itemType/addItemType`,

  /**
   * 门店类型删除
   * method DELETE
   */
  MERCHANT_ROOM_TYPE_DELETE: `${SERVER}/merchant/store/itemType/delItemType`,

  /**
  * 获取商家管理员
  * method GET
  */
  MERCHANT_MANAGER_LIST: `${SERVER}/merchant/user/list`,

  /**
  * 新增商家管理员
  * method POST
  */
  MERCHANT_MANAGER_ADD: `${SERVER}/merchant/user/add`,

  /**
  * 编辑商家管理员
  * method POST
  */
  MERCHANT_MANAGER_EDIT: `${SERVER}/merchant/user/edit`,

  /**
  * 删除商家管理员
  * method DELETE
  */
  MERCHANT_MANAGER_DELETE: `${SERVER}/merchant/user/del`,


});
