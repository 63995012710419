/**
 * 订单接口
 */
export default SERVER => ({
  /**
   * 订单列表
   * method GET
   */
  ORDER_LIST: `${SERVER}/merchant/store/trade/getStoreTradeList`,

});
