export default {
  DATA_KANBAN: require('./data-kanban.png'),

  MORE: require('./more.png'),
  
  HEAD: require('./head.png'),
  ARROW_DOWN: require('./arrow-down.png'),

  WECHAT: require('./wechat.png'),
  TIKTOK: require('./tiktok.png'),
  COUPON: require('./coupon.png'),
  BALANCE: require('./balance.png'),
  MEITUAN: require('./meituan.png'),

};
