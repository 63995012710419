/**
 * 门店 - 房间接口
 */
export default SERVER => ({
  /**
   * 获取房间列表
   * method GET
   */
  ROOM_LIST: `${SERVER}/merchant/store/item/getItemList`,

  /**
   * 新增房间
   * method POST
   */
  ROOM_ADD: `${SERVER}/merchant/store/item/addItem`,

  /**
   * 删除房间
   * method DELETE
   */
  ROOM_DELETE: `${SERVER}/merchant/store/item/delItem`,

  /**
   * 房间详情
   * method GET
   * /merchant/store/item/getItemDetail/{itemId}
   */
  ROOM_DETAIL: `${SERVER}/merchant/store/item/getItemDetail/`,

  /**
   * 编辑房间
   * method POST
   */
  ROOM_EDIT: `${SERVER}/merchant/store/item/modifyItemInfo`,

  /**
   * 修改房间状态
   * method POST
   */
  ROOM_ENABLE: `${SERVER}/merchant/store/item/modifyEnableState`,
  

  /**
   * 空开测试
   * method GET
   */
  ROOM_AOCN_TEST: `${SERVER}/store/item/device/aocnTest`,

  /**
   * 音响测试
   * method GET
   */
  ROOM_AUDIO_TEST: `${SERVER}/store/item/device/audioTest`,

  /**
   * 门锁测试
   * method GET
   */
  ROOM_LOCK_TEST: `${SERVER}/store/item/device/lockTest`,

});
