import auth from './auth';
import common from './common';
import user from './user';
import store from './store';
import room from './room';
import member from './member';
import order from './order';
import merchant from './merchant';
import coupon from './coupon';

// ----------------------------------
// SERVER
// ----------------------------------
// const SERVER_DEVELOPMENT = 'http://119.23.248.54:6001/manager';
const SERVER_DEVELOPMENT = `https://www.wrzztech.top/manager`;
const SERVER_PRODUCTION = `https://www.wrzztech.top/manager`;

export const SERVER = `${__DEV__ ? SERVER_DEVELOPMENT : SERVER_PRODUCTION}`;

export default {
  ...auth(SERVER),
  ...common(SERVER),
  ...user(SERVER),
  ...store(SERVER),
  ...room(SERVER),
  ...member(SERVER),
  ...order(SERVER),
  ...merchant(SERVER),
  ...coupon(SERVER),
};
