/**
 * 会员接口
 */
export default SERVER => ({
  /**
   * 会员列表
   * method GET
   */
  MEMBER_LIST: `${SERVER}/merchant/member/list`,

  /**
   * 会员订单列表
   * method GET
   */
  MEMBER_ORDER_LIST: `${SERVER}/merchant/store/member/getMemberTradeList`,

  /**
   * 会员基本信息
   * method GET
   */
  MEMBER_INFO: `${SERVER}/merchant/store/member/getMemberInfo`,

  /**
   * 赠送优惠券
   * method GET
   */
  MEMBER_GIVE_COUPON: `${SERVER}/merchant/coupon/give`,

  /**
   * 已有优惠券
   * method GET
   */
  MEMBER_COUPON_LIST: `${SERVER}/merchant/store/member/getCouponList`,

});
