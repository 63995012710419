/**
 * 消费券相关接口
 */
export default SERVER => ({

  /**
   * 获取商家三方消费券配置 - 抖音、美团券配置
   * method GET
   */
  MERCHANT_THIRD_COUPON_CONFIG: `${SERVER}/merchant/system/setting/getSetting`,

  /**
  * 设置商家三方消费券配置
  * method POST
  */
  MERCHANT_THIRD_COUPON_CONFIG_EDIT: `${SERVER}/merchant/system/setting/editSetting`,

  /**
  * 分页获取平台优惠券列表
  * method GET
  */
  MERCHANT_COUPON_LIST: `${SERVER}/merchant/coupon/getList`,

  /**
  * 商家消费券详情
  * method GET
  */
  MERCHANT_COUPON_DETAIL: `${SERVER}/merchant/coupon/get`,

  /**
  * 新增商家消费券
  * method POST
  */
  MERCHANT_COUPON_ADD: `${SERVER}/merchant/coupon/add`,
  
  /**
  * 编辑商家消费券
  * method DELETE
  */
  MERCHANT_COUPON_EDIT: `${SERVER}/merchant/coupon/edit`,

  /**
  * 删除商家消费券
  * method DELETE
  */
  MERCHANT_COUPON_DELETE: `${SERVER}/merchant/coupon/delCoupon`,

  /**
   * 获取赠送优惠券列表 商户 todo优化接口
   * method GET
   */
  MERCHANT_GET_GIVE_COUPON_LIST: `${SERVER}/merchant/coupon/getGiveCouponList`,

  /**
   * 获取赠送优惠券列表 门店
   * method GET
   */
  COUPON_GET_GIVABLE_COUPON_LIST: `${SERVER}/merchant/coupon/getGiveAbleList`,

  /**
   * 获取美团授权码回调
   * method GET
   */
  MERCHANT_MEITUAN_AUTH_CODE: `${SERVER}/manager/passport/user/meiTuanAuth`,

});