/**
 * 门店接口
 */
export default SERVER => ({
  /**
   * 获取门店列表
   * method POST
   */
  STORE_LIST: `${SERVER}/merchant/store/store/getStoreList`,

  /**
   * 新增门店
   * method POST
   */
  STORE_ADD: `${SERVER}/merchant/store/store/addStore`,

  /**
   * 删除门店
   * method DELETE
   */
  STORE_DELETE: `${SERVER}/merchant/store/store/delStore`,

  /**
   * 门店详情
   * method GET
   * /merchant/store/store/getStoreDetail/{storeId}
   */
  STORE_DETAIL: `${SERVER}/merchant/store/store/getStoreDetail/`,

  /**
   * 编辑门店
   * method POST
   */
  STORE_EDIT: `${SERVER}/merchant/store/store/modifyStoreInfo`,

  /**
   * 门店经营配置
   * method GET
   */
  STORE_OP_SETTING: `${SERVER}/merchant/store/setting/getStoreSetting`,

  /**
   * 门店经营设置
   * method POST
   */
  STORE_OP_SETTING_EDIT: `${SERVER}/merchant/store/setting/modifyStoreSetting`,

  /**
   * 门店门店活动配置
   * method POST
   */
  STORE_ACTIVITY_CONFIG: `${SERVER}/merchant/store/activity/getActivitySetting`,
  /**
   * 编辑门店活动配置
   * method POST
   */
  STORE_ACTIVITY_EDIT: `${SERVER}/merchant/store/activity/modifyActivitySetting`,


  /**
   * 获取门店三方消费券配置 - 抖音、美团券配置
   * method GET
   */
  STORE_THIRD_COUPON_CONFIG: `${SERVER}/store/system/setting/getSetting`,

  /**
  * 设置门店三方消费券配置
  * method POST
  */
  STORE_THIRD_COUPON_CONFIG_EDIT: `${SERVER}/store/system/setting/editSetting`,

  /**
  * 分页获取平台优惠券列表
  * method GET
  */
  STORE_COUPON_LIST: `${SERVER}/store/coupon/getList`,

  /**
  * 门店消费券详情
  * method GET
  */
  STORE_COUPON_DETAIL: `${SERVER}/store/coupon/get`,

  /**
  * 新增门店消费券
  * method POST
  */
  STORE_COUPON_ADD: `${SERVER}/store/coupon/add`,
  
  /**
  * 编辑门店消费券
  * method DELETE
  */
  STORE_COUPON_EDIT: `${SERVER}/store/coupon/edit`,

  /**
  * 删除门店消费券
  * method DELETE
  */
  STORE_COUPON_DELETE: `${SERVER}/store/coupon/delCoupon`,
});
