/**
 * 菜单控件
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HomeOutlined,
  FileTextOutlined,
  DashboardOutlined,
  BankOutlined,
  TeamOutlined,
  FileOutlined,
  AccountBookOutlined,
} from '@ant-design/icons';

// components

// actions

// utils

// constant

// style
import './style.scss';

const className = 'componentLeftNav';

const SubMenu = Menu.SubMenu;

// 商户菜单
const MENU_LIST = [{
  title: '门店管理',
  path: '/',
  icon: <HomeOutlined />
}, {
  title: '商家活动配置',
  path: '/merchant/activity',
  icon: <DashboardOutlined />
}, {
  title: '数据字典设置',
  path: '/merchant/data-dictionary',
  icon: <BankOutlined />
}, {
  title: '美团、抖音券设置',
  path: '/merchant/third-coupon',
  icon: <FileTextOutlined />
}, {
  title: '优惠券设置',
  path: '/merchant/coupon',
  icon: <FileTextOutlined />
}, {
  title: '管理员设置',
  path: '/merchant/manager-setting',
  icon: <TeamOutlined />
}
];

// 商户门店管理菜单
const MERCHANT_MENU_LIST = [{
  title: '房间管理',
  path: '/store/room-manage',
  icon: <HomeOutlined />
}, {
  title: '用户管理',
  path: '/store/member-manage',
  icon: <TeamOutlined />
}, {
  title: '订单管理',
  path: '/store/order-manage',
  icon: <FileOutlined />
}, {
  title: '活动设置',
  path: '/store/activity',
  icon: <FileOutlined />
},
{
  title: '经营设置',
  path: '/store/operate-setting',
  icon: <AccountBookOutlined />
},
// {
//   title: '计费设置',
//   path: '/store/price-setting',
//   icon: <DollarOutlined />
// },
{
  title: '美团、抖音券设置',
  path: '/store/third-coupon',
  icon: <AccountBookOutlined />
},
// {
//   title: '店员设置',
//   path: '/store/clerk-setting',
//   icon: <SmileOutlined />
// }
];

class LeftNav extends Component {
  getMenuNodes = menuList => {
    return menuList.reduce((pre, item) => {
      if (!item.items) {
        pre.push((
          <Menu.Item key={item.path} icon={item.icon}>
            <div onClick={() => window.location.href=item.path}>{item.title}</div>
          </Menu.Item>
        ))
      } else {
        pre.push((
          <SubMenu key={item.path} icon={item.icon} title={item.title}>{this.getMenuNodes(item.items)}</SubMenu>
        ))
      }
      return pre;
    }, []);
  }

  render() {
    const path = this.props.location.pathname;

    let menu = MENU_LIST;

    // store 为房间管理链接
    if (path.indexOf('store') > -1) {
      menu = MERCHANT_MENU_LIST;
    }

    return (
      <div className={`${className}`}>
        <Menu
          mode='inline'
          theme='light'
          selectedKeys={[path]}
        >
          {this.getMenuNodes(menu)}
        </Menu>
      </div>
    );
  }
}

export default withRouter(LeftNav);