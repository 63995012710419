import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { injectReducer } from '../store/reducers';
import rootStore from '../store';

// --------------  登录  --------------
// 登录
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ './Auth/SignIn'));

// --------------  首页 - 门店管理  --------------
const Index = lazy(() => import(/* webpackChunkName: "Home" */ './Index/Index'));
// --------------  商户 - 活动管理  --------------
const MerchantActivity = lazy(() => import(/* webpackChunkName: "MerchantActivity" */ './MerchantActivity/Index'));
// --------------  商户 - 数据字典  --------------
const MerchantDataDictionary = lazy(() => import(/* webpackChunkName: "MerchantDataDictionary" */ './MerchantDataDictionary/Index'));
// --------------  商户 - 三方消费券配置  --------------
const MerchantThirdCoupon = lazy(() => import(/* webpackChunkName: "MerchantThirdCoupon" */ './MerchantThirdCoupon/Index'));
// --------------  商户 - 优惠券配置  --------------
const MerchantCoupon = lazy(() => import(/* webpackChunkName: "MerchantCoupon" */ './MerchantCoupon/Index'));
// --------------  商户 - 管理员配置  --------------
const MerchantManagerSetting = lazy(() => import(/* webpackChunkName: "MerchantManagerSetting" */ './MerchantManagerSetting/Index'));


// --------------  门店 - 房间管理  --------------
const RoomManage = lazy(() => import(/* webpackChunkName: "RoomManage" */ './RoomManage/Index'));
// --------------  门店 - 用户管理  --------------
const MemberManage = lazy(() => import(/* webpackChunkName: "MemberManage" */ './MemberManage/Index'));
// --------------  门店 - 订单管理  --------------
const OrderManage = lazy(() => import(/* webpackChunkName: "OrderManage" */ './OrderManage/Index'));
// --------------  门店 - 经营设置  --------------
const OperateSetting = lazy(() => import(/* webpackChunkName: "OperateSetting" */ './OperateSetting/Index'));
// --------------  门店 - 活动  --------------
const StoreActivity = lazy(() => import(/* webpackChunkName: "StoreActivity" */ './StoreActivity/Index'));
// --------------  门店 - 三方消费券配置  --------------
const StoreThirdCoupon = lazy(() => import(/* webpackChunkName: "StoreThirdCoupon" */ './StoreThirdCoupon/Index'));

// 404
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ './PageNotFound'));

const Conponents = {
  // 登录
  SignIn,

  // 首页 - 商户管理
  Index,
  // 商户活动
  MerchantActivity,
  // 商户 数据字典
  MerchantDataDictionary,
  // 商户 - 三方消费券配置
  MerchantThirdCoupon,
  // 商户 - 优惠券配置
  MerchantCoupon,
  // 商户 - 三方卡券配置
  MerchantThirdCoupon,
  // 商户 - 管理员配置
  MerchantManagerSetting,

  // 门店-房间管理
  RoomManage,
  // 门店-用户管理
  MemberManage,
  OrderManage,
  OperateSetting,
  StoreActivity,
  StoreThirdCoupon,

  PageNotFound,
};

// 查找reducer，component_name 到 path 的映射
const COMPONENT_PATH_MAPPING = {
  // 登录
  'SignIn': 'Auth/SignIn',

  // 首页-商户管理
  'Index': 'Index/Index',
  'MerchantActivity': 'MerchantActivity/Index',
  'MerchantDataDictionary': 'MerchantDataDictionary/Index',
  'MerchantCoupon': 'MerchantCoupon/Index',
  'MerchantThirdCoupon': 'MerchantThirdCoupon/Index',
  'MerchantManagerSetting': 'MerchantManagerSetting/Index',

  // 门店-房间管理
  'RoomManage' : 'RoomManage/Index',
  // 门店-用户管理
  'MemberManage' : 'MemberManage/Index',
  'OrderManage' : 'OrderManage/Index',
  'OperateSetting' : 'OperateSetting/Index',
  'StoreActivity' : 'StoreActivity/Index',
  'StoreThirdCoupon': 'StoreThirdCoupon/Index',

  'PageNotFound': 'PageNotFound',
}

const AsyncComponent = props => {
  const {componentName} = props;

  import(`./${COMPONENT_PATH_MAPPING[componentName]}/reducer`).then(({default: reducer}) => {
    injectReducer(rootStore, {key: componentName, reducer});
  });

  const Component = Conponents[componentName];

  return <Component {...props} />;
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired
};

AsyncComponent.defaultProps = {};

export default AsyncComponent;
