/**
 * 登录接口
 */
export default SERVER => ({

  /**
   * 登录
   * method POST
   */
  AUTH_SIGN_IN: `${SERVER}/merchant/passport/user/login`,

});
