// -----------------------------
// 常量配置
// -----------------------------
import keyMirror from 'keymirror';

// eslint-disable-next-line
export const LOCAL_STORAGE = keyMirror({
  CONFIG: null,
  ACCESS_TOKEN: null,
  REFRESH_TOKEN: null,
  USER_INFO: null,

  // 门店信息
  STORE_ARRAY: null,
  STORE_ID: null, // 当前选择门店id
});

// 分页大小
export const PAGE_SIZE = 10;

// 七牛上传图片链接
export const QINIU_UPLOAD_URL = 'https://up-z2.qiniup.com';

// 图片 BASE_URL
export const IMAGE_BASE_URL = 'https://www.wrzztech.top/img/';

export const SLIDER_MARKS = {0.5: '0.5', 1: '1', 1.5: '1.5', 2: '2', 2.5: '2.5', 3: '3', 3.5: '3.5', 4: '4', 4.5: '4.5', 5: '5', 5.5: '5.5', 6: '6', 6.5: '6.5', 
7: '7', 7.5: '7.5', 8: '8', 8.5: '8.5', 9: '9', 9.5: '9.5', 10: '10'};
