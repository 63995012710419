/**
 * 公共接口
 */
export default SERVER => ({

  /**
   * 七牛token
   * method GET
   */
  COMMON_QINIU_TOKEN: `${SERVER}/manager/common/file/getToken`,

  /**
   * 数据字典 新增
   * method POST
   */
  COMMON_DICT_ADD: `${SERVER}/manager/dict/add`,

  /**
   * 数据字典 删除
   * method POST
   */
  COMMON_DICT_DEL: `${SERVER}/manager/dict/del`,

  /**
   * 数据字典列表
   * method GET
   */
  COMMON_DICT_LIST: `${SERVER}/manager/dict/get`,

});