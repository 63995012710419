import { PropTypes } from 'prop-types';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import AppLayout from '../layout';
import AsyncComponent from '../routes';
import LoadingPlaceholder from '../components/LoadingPlaceholder';

const AppContainer = ({ store, history }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <AppLayout>
            <Suspense fallback={<LoadingPlaceholder />}>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={props => <AsyncComponent {...props} componentName='Index' />}
                />
                <Route
                  exact
                  path='/merchant/activity'
                  render={props => <AsyncComponent {...props} componentName='MerchantActivity' />}
                />
                <Route
                  exact
                  path='/merchant/data-dictionary'
                  render={props => <AsyncComponent {...props} componentName='MerchantDataDictionary' />}
                />
                <Route
                  exact
                  path='/merchant/third-coupon'
                  render={props => <AsyncComponent {...props} componentName='MerchantThirdCoupon' />}
                />
                <Route
                  exact
                  path='/merchant/coupon'
                  render={props => <AsyncComponent {...props} componentName='MerchantCoupon' />}
                />
                <Route
                  exact
                  path='/merchant/manager-setting'
                  render={props => <AsyncComponent {...props} componentName='MerchantManagerSetting' />}
                />
                

                <Route
                  exact
                  path='/signin'
                  render={props => <AsyncComponent {...props} componentName='SignIn' />}
                />

                <Route
                  exact
                  path='/store/room-manage'
                  render={props => <AsyncComponent {...props} componentName='RoomManage' />}
                />
                <Route
                  exact
                  path='/store/member-manage'
                  render={props => <AsyncComponent {...props} componentName='MemberManage' />}
                />
                <Route
                  exact
                  path='/store/order-manage'
                  render={props => <AsyncComponent {...props} componentName='OrderManage' />}
                />
                <Route
                  exact
                  path='/store/activity'
                  render={props => <AsyncComponent {...props} componentName='StoreActivity' />}
                />
                <Route
                  exact
                  path='/store/third-coupon'
                  render={props => <AsyncComponent {...props} componentName='StoreThirdCoupon' />}
                />
                <Route
                  exact
                  path='/store/operate-setting'
                  render={props => <AsyncComponent {...props} componentName='OperateSetting' />}
                />

                <Route
                  render={props => <AsyncComponent {...props} componentName='PageNotFound' />}
                />
              </Switch>
            </Suspense>
          </AppLayout>
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>
  );
};

AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AppContainer;
