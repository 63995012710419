import { LOCAL_STORAGE } from '../constants';

// 系统临时数据

// ------------------- 门店列表
export function setStoreArray(storeArray) {
  window.localStorage[LOCAL_STORAGE.STORE_ARRAY] = JSON.stringify(storeArray);
}

export function getStoreArray() {
  if (window.localStorage[LOCAL_STORAGE.STORE_ARRAY]) {
    return JSON.parse(window.localStorage[LOCAL_STORAGE.STORE_ARRAY]);
  }

  return [];
}

export function setStoreId(id) {
  window.localStorage[LOCAL_STORAGE.STORE_ID] = id;
}

export function getStoreId() {
  if (window.localStorage[LOCAL_STORAGE.STORE_ID]) {
    return window.localStorage[LOCAL_STORAGE.STORE_ID];
  }

  return 0;
}


export function getCurrentStore() {
  const storyArray = getStoreArray();
  const id = getStoreId();

  for (let i = 0; i < storyArray.length; i++) {
    if (storyArray[i].storeId == id) {
      return storyArray[i];
    }
  }
  
  return {};
}
