
/**
 * 获取 面包屑 信息
 */
export function breadcrumbInfo() {
  const pathName = window.location.pathname; // root = '/'
  const isSignIn = (pathName.indexOf('signin') !== -1);

  if (isSignIn) {
    return null;
  }

  if (pathName == '/') {
    return [{ title: '门店管理' }];
  }

  if (pathName == '/merchant/activity') {
    return [{ title: '门店活动配置' }];
  }

  if (pathName == '/merchant/room-type') {
    return [{ title: '房间类型设置' }];
  }

  if (pathName == '/merchant/coupon') {
    return [{ title: '消费券设置' }];
  }

  if (pathName == '/merchant/third-coupon') {
    return [{ title: '美团、抖音券设置' }];
  }

  if (pathName == '/merchant/manager-setting') {
    return [{ title: '管理员设置' }];
  }

  if (pathName == '/store/room-manage') {
    return [{ title: '门店管理', href: '/' }, { title: '房间管理' }];
  }

  if (pathName == '/store/member-manage') {
    return [{ title: '门店管理', href: '/' }, { title: '用户管理' }];
  }

  if (pathName == '/store/order-manage') {
    return [{ title: '门店管理', href: '/' }, { title: '订单管理' }];
  }

  if (pathName == '/store/activity') {
    return [{ title: '门店管理', href: '/' }, { title: '活动设置' }];
  }

  if (pathName == '/store/third-coupon') {
    return [{ title: '门店管理', href: '/' }, { title: '美团、抖音券设置' }];
  }

  if (pathName == '/store/operate-setting') {
    return [{ title: '门店管理', href: '/' }, { title: '经营设置' }];
  }
}
